<template>
  <base-widget
    :deviceIds="formattedDeviceIds"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :title="name"
    :subtitle="deviceType"
    @removeWidget="removeWidget"
    @refreshDashboard="refreshDashboard"
    data-cy="currentReadingsWidget"
  >
    <div slot="actions">
      <measurement-options
        :selectedDevices="selectedDevices"
        :passedinParameter="selectedParameter"
        :permissions="permissions"
        @parameterChange="parameterChange"
      />
      <v-divider></v-divider>
    </div>
    <popover-widget-toolbar slot="toolbar" :deviceId="deviceId" />
    <div slot="left-toolbar" class="toolbar">
      <v-btn text @click="refresh" data-cy="refreshPopover">
        <v-icon color="grey">mdi-refresh</v-icon>
      </v-btn>
    </div>
    <div slot="content">
      <div v-if="!isLoading">
        <popover-timestamp :deviceId="deviceId" />
        <one-measurement-monitoring
          :deviceId="deviceId"
          :selectedParameter="selectedParameter"
        />
      </div>
      <loading-container v-else />
    </div>
    <popover-last-calibration-date slot="left-footer" :deviceId="deviceId" />
    <popover-current-calibration-factor
      slot="right-footer"
      :deviceId="deviceId"
    />
  </base-widget>
</template>

<script>
import BaseWidget from '../../BaseWidget.vue'
import LoadingContainer from '../../../devicePopover/content/layouts/LoadingContainer.vue'
import popoverTimestamp from '../../../devicePopover/content/popoverTimestamp.vue'
import popoverToolbar from '../../../devicePopover/title/popoverToolbar.vue'
import popoverLastCalibrationDate from '../../../devicePopover/content/popoverLastCalibrationDate.vue'
import popoverCurrentCalibrationFactor from '../../../devicePopover/content/popoverCurrentCalibrationFactor.vue'
import OneMeasurementMonitoring from '../../../devicePopover/content/layouts/OneMeasurementMonitoring.vue'
import MeasurementOptions from '../../../devicePopover/dropdown/MeasurementOptions.vue'
import { widgetSettings } from '../../../../services/dashboard'
import { deviceModelDisplay } from '../../../../services/map'
import { getPermissionsByDeviceId } from '../../../../services/subscriptions'
import { getUserAccountId } from '../../../../helpers/loginas/logInAsHelper'
import { DEVICE_UPDATE_INTERVAL_MS } from '../../../../services/device-metadata'
import { measurementTypes } from '../../../../services/device-measurements'

export default {
  name: 'ContinuousMonitoring',
  components: {
    'base-widget': BaseWidget,
    'loading-container': LoadingContainer,
    'popover-widget-toolbar': popoverToolbar,
    'popover-timestamp': popoverTimestamp,
    'popover-last-calibration-date': popoverLastCalibrationDate,
    'popover-current-calibration-factor': popoverCurrentCalibrationFactor,
    'one-measurement-monitoring': OneMeasurementMonitoring,
    'measurement-options': MeasurementOptions,
  },
  mixins: [widgetSettings],
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: Array,
    settings: Object,
  },
  data() {
    return {
      accountId: null,
      loadDataInterval: null,
      selectedParameter: null,
      isLoading: false,
    }
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    await this.initWidget()
  },
  destroyed() {
    this.stopLoadDataInterval()
  },
  watch: {
    async deviceId(deviceId) {
      if (deviceId) {
        await this.initWidget()
      }
    },
    selectedParameter(selectedParameter) {
      this.saveWidgetSettings(this.widgetId, { selectedParameter })
    },
  },
  computed: {
    formattedDeviceIds() {
      return this.deviceIds?.length ? this.deviceIds : []
    },
    deviceId() {
      return this.formattedDeviceIds[0]
    },
    name() {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    deviceType() {
      const modelKey = this.$store.getters['devicemodels/getModelKey'](
        this.model
      )
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    selectedDevices() {
      return this.formattedDeviceIds.map((deviceId) => {
        return {
          id: deviceId,
          friendlyName:
            this.$store.getters['devices/getNameByDeviceId'](deviceId),
          model: this.$store.getters['devices/getModelByDeviceId'](deviceId),
          submodel:
            this.$store.getters['devices/getSubmodelByDeviceId'](deviceId),
          isMine:
            this.accountId ===
            this.$store.getters['devices/getAccountByDeviceId'](deviceId),
          isShared:
            this.$store.getters['devices/getIsSharedByDeviceId'](deviceId),
        }
      })
    },
    permissions() {
      let perms = {}
      for (const device of this.selectedDevices) {
        const deviceId = device.id
        perms[deviceId] = getPermissionsByDeviceId(deviceId)
      }
      return perms
    },
  },
  methods: {
    async initWidget() {
      this.applyWidgetSettings()
      this.stopLoadDataInterval()
      await this.populateWidgetData()
      this.startLoadDataInterval()
    },
    startLoadDataInterval() {
      if (this.loadDataInterval === null) {
        this.loadDataInterval = setInterval(
          this.populateWidgetData,
          DEVICE_UPDATE_INTERVAL_MS
        )
      }
    },
    stopLoadDataInterval() {
      if (this.loadDataInterval !== null) {
        clearInterval(this.loadDataInterval)
        this.loadDataInterval = null
      }
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
    async refresh() {
      this.populateWidgetData()
    },
    parameterChange(newValue) {
      this.selectedParameter = newValue
    },
    applyWidgetSettings() {
      let defaultParameter = measurementTypes.pm25_raw
      if (this.settings) {
        const { selectedParameter } = this.settings
        if (typeof selectedParameter === 'number') {
          defaultParameter = selectedParameter
        }
      }

      this.selectedParameter = defaultParameter
    },
    async populateWidgetData() {
      this.isLoading = true
      await Promise.all([
        this.$store.dispatch(
          'devices/getLatestTelemetryAndUpdate',
          this.deviceId
        ),
        this.$store.dispatch(
          'devices/getDeviceDetailsAndUpdate',
          this.deviceId
        ),
      ])
      this.isLoading = false
    },
  },
}
</script>
