<template>
  <v-card-subtitle class="small-subtitle" data-cy="currentCalibrationFactor">
    <span> {{ $t('map.popover.currentCalibrationFactor') }}: </span>
    <span class="value">
      {{ currentCalibrationFactor }}
    </span>
  </v-card-subtitle>
</template>

<script>
export default {
  props: {
    deviceId: String,
  },
  computed: {
    // TO DO: BI-8391
    currentCalibrationFactor() {
      return 'XXX'
    },
  },
}
</script>
