import { render, staticRenderFns } from "./popoverTimestamp.vue?vue&type=template&id=5b2772ec&scoped=true&"
import script from "./popoverTimestamp.vue?vue&type=script&lang=js&"
export * from "./popoverTimestamp.vue?vue&type=script&lang=js&"
import style0 from "./popoverTimestamp.vue?vue&type=style&index=0&id=5b2772ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2772ec",
  null
  
)

export default component.exports