import { publicMeasurementPermissions } from '../../services/device-measurements'

export default {
  getAccountByDeviceId: (state) => (deviceId) => {
    return state.accountId[deviceId]
  },
  getCoordsByDeviceId: (state) => (deviceId) => {
    return state.coords[deviceId]
  },
  getSensorReadingsByDeviceId: (state) => (deviceId) => {
    return state.sensorReadings[deviceId]
  },
  getDeviceStatusByDeviceId: (state) => (deviceId) => {
    return state.deviceStatus[deviceId] || []
  },
  getSensorStatusByDeviceId: (state) => (deviceId) => {
    return state.sensorStatus[deviceId] || []
  },
  getRSSIByDeviceId: (state) => (deviceId) => {
    return state.rssi[deviceId]
  },
  getConnectionTypeByDeviceId: (state) => (deviceId) => {
    return state.connectionType[deviceId]
  },
  getSpsEoLByDeviceId: (state) => (deviceId) => {
    return state.sensorEoL[deviceId]
  },
  getUserCalsByDeviceId: (state) => (deviceId) => {
    return state.userCals[deviceId]
  },
  getFirmwareByDeviceId: (state) => (deviceId) => {
    return state.firmware[deviceId]
  },
  getAveragesByDeviceId: (state) => (deviceId) => {
    return state.averages[deviceId]
  },
  getPercentChangedByDeviceId: (state) => (deviceId) => {
    return state.percentChanged[deviceId]
  },
  getIsPublicByDeviceId: (state) => (deviceId) => {
    return state.public[deviceId]
  },
  getPrivacyZoneByDeviceId: (state) => (deviceId) => {
    return state.privacyZone[deviceId]
  },
  getLastContactByDeviceId: (state) => (deviceId) => {
    return state.lastTelemetry[deviceId]
  },
  getLastCommunicationTimeByDeviceId: (state) => (deviceId) => {
    return state.lastCommunicationTime[deviceId] || null
  },
  getLastTelemetryTimestampByDeviceId: (state) => (deviceId) => {
    if (state.lastTelemetry[deviceId]) {
      return state.lastTelemetry[deviceId]
    }
    return null
  },
  getLastCalibrationDate: (state) => (deviceId) => {
    return state.lastCalibrationDate[deviceId] || null
  },
  getIsIndoorByDeviceId: (state) => (deviceId) => {
    return state.indoor[deviceId]
  },
  getIsSharedByDeviceId: (state) => (deviceId) => {
    return state.shared[deviceId]
  },
  getZeroAppliedAtByDeviceId: (state) => (deviceId) => {
    return state.zeroAppliedAt[deviceId]
  },
  getZeroStateByDeviceId: (state) => (deviceId) => {
    return state.zeroState[deviceId]
  },
  getNameByDeviceId: (state) => (deviceId) => {
    return state.name[deviceId]
  },
  getLocationNameByDeviceId: (state) => (deviceId) => {
    return state.locationName[deviceId]
  },
  getModelByDeviceId: (state) => (deviceId) => {
    return state.model[deviceId]
  },
  getSubmodelByDeviceId: (state) => (deviceId) => {
    return state.submodel[deviceId]
  },
  getDeviceTypeByDeviceId: (state) => (deviceId) => {
    const model = state.model[deviceId]
    const submodel = state.submodel[deviceId]
    // TODO: Submodel should come from some field in the firestore device model metadata for the model's submodel, see https://jira.tsi.com/browse/FTD-2127
    return submodel ? `${model}-0${submodel[0]}` : model
  },
  getSerialByDeviceId: (state) => (deviceId) => {
    return state.serial[deviceId]
  },
  getMeasurementsByDeviceId: (state) => (deviceId) => {
    return state.measurements[deviceId]
  },
  getSubscriptionMetadataByDeviceId: (state) => (deviceId) => {
    return state.subscriptionMetadata[deviceId]
  },
  getSubscriptionTypeByDeviceId: (state) => (deviceId) => {
    return state.subscriptionType[deviceId]
  },
  getWebsiteSubscriptionSubType: (state) => (deviceId) => {
    const websiteSubscription = state.subscriptionMetadata[deviceId]?.website
    return websiteSubscription?.subType // undefined if there is no website subscription
  },
  getPermissionsByDeviceId: (state) => (deviceId) => {
    if (state.subscriptionMetadata && state.subscriptionMetadata[deviceId]) {
      const metadata = state.subscriptionMetadata[deviceId]
      if (metadata.permissions && Array.isArray(metadata.permissions)) {
        return metadata.permissions
      }
    }
    // allow reading some measurements from public devices
    if (state.public && state.public[deviceId]) {
      return publicMeasurementPermissions
    }
    return []
  },
  getDeviceIdByDeviceSerial: (state) => (serial) => {
    return serial
      ? Object.keys(state.serial).find((key) => state.serial[key] === serial)
      : ''
  },
  getConnectedSmartBridgeModelByDeviceId: (state) => (deviceId) => {
    return state.connectedSmartBridgeModel[deviceId]
  },
  getConnectedSmartBridgeSerialByDeviceId: (state) => (deviceId) => {
    return state.connectedSmartBridgeSerial[deviceId]
  },
  getConnectedSmartBridgeIdByDeviceId: (state, getters) => (deviceId) => {
    const smartBridgeSerial =
      getters.getConnectedSmartBridgeSerialByDeviceId(deviceId) || ''
    return getters.getDeviceIdByDeviceSerial(smartBridgeSerial) || ''
  },
  getSmartBridgeConnectedDeviceIdsByDeviceId:
    (state, getters) => (deviceId) => {
      const smartBridgeSerial = getters.getSerialByDeviceId(deviceId)
      if (smartBridgeSerial) {
        return Object.keys(state.connectedSmartBridgeSerial).filter(
          (key) => state.connectedSmartBridgeSerial[key] === smartBridgeSerial
        )
      }
      return []
    },
  getIsConnectedByDeviceId: (state) => (deviceId) => {
    return !!state.isConnected[deviceId]
  },
  getTelemTxTypeByDeviceId: (state) => (deviceId) => {
    return state.telemTxType[deviceId]
  },
  getIsOfflinePresentByDeviceId: (state) => (deviceId) => {
    return !!state.uploadStatus[deviceId]?.offline_present
  },
  getIsAutoUploadByDeviceId: (state) => (deviceId) => {
    return !!state.uploadStatus[deviceId]?.auto_upload
  },
  getIsLivePresentByDeviceId: (state) => (deviceId) => {
    return !!state.uploadStatus[deviceId]?.live_present
  },
  getIsBatteryChargingByDeviceId: (state) => (deviceId) => {
    return !!state.batteryCharging[deviceId]
  },
  getIsBatteryInstalledByDeviceId: (state) => (deviceId) => {
    return !!state.batteryInstalled[deviceId]
  },
  getIsBatteryLowByDeviceId: (state) => (deviceId) => {
    return !!state.batteryLow[deviceId]
  },
  getBatteryRemainingByDeviceId: (state) => (deviceId) => {
    return state.batteryRemaining[deviceId]
  },
  // getIsThereAValue this func determines if a value is diff from null and undefined
  getIsThereAValue: (state) => (deviceId, key) => {
    if (state.hasOwnProperty(key)) {
      const value = state[key][deviceId]
      return value !== null && value !== undefined
    }
    return false
  },
}
