<template>
  <div :class="`one-measurement-wrapper ${size}`">
    <popover-measurement
      :reading="reading"
      :label="currentMeasurement.label"
      :unitsLabel="currentMeasurement.unitsLabel"
      :imageSrc="currentMeasurement.imgSrc"
      :largeMeasurement="true"
    />
  </div>
</template>

<script>
import popoverMeasurement from '../popoverMeasurement.vue'
import { getReadingValue } from '../../utils'
import { convertArrayToObject } from '../../../../services/data-conversion'
import {
  measurementTypes,
  measurementTypesKey,
  measurementTypesUnitsKey,
  measurementFriendlyNameKey,
  measurementImgSrc,
  measurementTypesOrder,
} from '../../../../services/device-measurements.js'
import { Measurement_Names } from '../../../../store/devices/utils.js'

export default {
  components: {
    'popover-measurement': popoverMeasurement,
  },
  props: {
    deviceId: { type: String, default: '' },
    selectedParameter: Number,
    size: { type: String, default: 'large' },
  },
  computed: {
    model() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    submodel() {
      return this.$store.getters['devices/getSubmodelByDeviceId'](this.deviceId)
    },
    supportedMeasurements() {
      return this.$store.getters['devicemodels/getSupportedMeasurements'](
        this.model,
        this.submodel
      ).sort((a, b) => {
        return (
          measurementTypesOrder.indexOf(a) - measurementTypesOrder.indexOf(b)
        )
      })
    },
    readings() {
      return convertArrayToObject(
        this.$store.getters['devices/getSensorReadingsByDeviceId'](
          this.deviceId
        ),
        'name'
      )
    },
    reading() {
      return this.currentMeasurement
        ? getReadingValue(this.readings, this.currentMeasurement.key)
        : null
    },
    defaultMeasurementId() {
      if (this.supportedMeasurements?.length) {
        return this.supportedMeasurements[0]
      }

      return measurementTypes.pm25_raw
    },
    currentMeasurementId() {
      return this.isMeasurementSupported(this.selectedParameter)
        ? this.selectedParameter
        : this.defaultMeasurementId
    },
    currentMeasurement() {
      return {
        key:
          Measurement_Names[measurementTypesKey[this.currentMeasurementId]] ||
          measurementTypesKey[this.currentMeasurementId],
        unitsLabel: this.$t(
          measurementTypesUnitsKey[this.currentMeasurementId]
        ),
        label: this.$t(measurementFriendlyNameKey[this.currentMeasurementId]),
        imgSrc: measurementImgSrc[this.currentMeasurementId],
      }
    },
  },
  methods: {
    isMeasurementSupported(measurementId) {
      return this.supportedMeasurements.includes(measurementId)
    },
  },
}
</script>

<style lang="scss">
.small-subtitle {
  .value {
    color: black;
    font-weight: 500;
  }
}
</style>

<style lang="scss" scoped>
.one-measurement-wrapper {
  ::v-deep .popover-measurement {
    display: block;

    .label {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .reading {
      display: flex;
      justify-content: center;
      align-items: baseline;
      line-height: 100%;
      color: black;
      gap: 0.5rem;

      .uom {
        font-weight: 400;
      }
    }

    img {
      height: unset;
    }
  }
}

.one-measurement-wrapper.large {
  ::v-deep .popover-measurement {
    margin: 30px 0;

    .label {
      font-size: 1.5rem;
    }

    .reading {
      margin-top: 35px;
      font-size: 5rem;

      .uom {
        font-size: 2rem;
      }
    }

    img {
      width: 50px;
      margin-right: 10px;
    }

    @media (max-width: 480px) {
      .label {
        font-size: 1.3rem;
      }

      .reading {
        font-size: 4rem;

        .uom {
          font-size: 1rem;
        }
      }

      img {
        width: 40px;
      }
    }
  }
}

.one-measurement-wrapper.small {
  ::v-deep .popover-measurement {
    margin: 4px 0;

    .label {
      font-size: 1.2rem;
    }

    .reading {
      margin-top: 5px;
      font-size: 2.5rem;

      .uom {
        font-size: 1.5rem;
      }
    }

    img {
      width: 35px;
      margin-right: 4px;
    }
  }
}

::v-deep .empty-reading {
  .reading {
    margin-top: 0 !important;
    color: unset !important;
  }
}
</style>
