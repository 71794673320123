<template>
  <v-card class="dashboard" flat :style="cssVars">
    <v-row no-gutters>
      <v-col cols="12" md="3">
        <v-card-title class="headline" data-cy="dashboardHeader">{{
          $t('dashboard.title')
        }}</v-card-title>
        <v-btn
          @click="openAddWidgets"
          text
          outlined
          :disabled="!totalDevices"
          class="black--text icon-btn card-title-btn"
        >
          <v-icon small class="append-plus">mdi-view-dashboard</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <v-card-text class="devices-summary">
          <router-link to="/manage-devices">
            {{ $t('dashboard.devices') }}
            <span md="3" class="devices-summary__group border-right">
              <span class="devices-count">{{ totalDevices }}</span>
              <span>{{ $t('dashboard.total') }}</span>
            </span>
            <span md="3" class="devices-summary__group border-right">
              <span class="active devices-count">{{ activeDevices }}</span>
              <span>{{ $t('dashboard.active') }}</span>
            </span>
            <span md="3" class="devices-summary__group">
              <span class="inactive devices-count">{{ inactiveDevices }}</span>
              <span>{{ $t('dashboard.inactive') }}</span>
            </span>
          </router-link>
        </v-card-text>
      </v-col>
    </v-row>

    <grid-layout
      v-show="!grid.loading"
      ref="dashboardGrid"
      class="dashboard-grid"
      :layout="grid.layout"
      :col-num="colNum"
      :row-height="grid.rowHeight"
      :margin="margin"
      :is-draggable="grid.isDraggable"
      :is-resizable="grid.isResizable"
      :vertical-compact="verticalCompact"
      :restore-on-drag="restoreOnDrag"
      :use-css-transforms="true"
      :responsive="false"
      :breakpoints="grid.breakpoints"
      :cols="grid.cols"
      @layout-updated="layoutUpdated"
    >
      <grid-item
        v-for="item in grid.layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :isResizable="item.isResizable"
        :maxW="item.maxW"
        :maxH="item.maxH"
        :class="{ 'widget--fullscreen': item.isFullscreen }"
        drag-allow-from=".vue-draggable-handle"
        drag-ignore-from=".no-drag, .widget-fullscreen .vue-draggable-handle"
        @resized="setWidgetDims"
        @container-resized="setWidgetDims"
        @moved="setWidgetMoved"
      >
        <component
          v-if="widgetsById[item.i]"
          :widgetId="widgetsById[item.i].id"
          :widgetType="widgetsById[item.i].widgetComponent"
          :widgetMaxDevices="widgetMaxDevices"
          :deviceIds="widgetsById[item.i].deviceIds"
          :width="widgetsById[item.i].width"
          :height="widgetsById[item.i].height"
          :settings="widgetsById[item.i].settings"
          :is="widgetsById[item.i].widgetComponent"
          :class="{ disabled: widgetsById[item.i].disabled }"
          @fullscreenChange="toggleFullscreen(item.i, $event)"
          @devicesChanged="updateWidgetDevices(item.i, $event)"
          @removeWidget="handleRemoveWidget(item.i, $event)"
          @refreshDashboard="refreshDashboard()"
        />
        <LoadingContainer v-if="!devicesLength" />
      </grid-item>
    </grid-layout>

    <div v-show="grid.loading" class="text-center emptyMessage">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div
      class="text-center empty-message"
      data-cy="dashboardEmptyMessage"
      v-if="showEmptyMessage"
    >
      {{ $t('dashboard.messages.empty') }}
    </div>
    <add-widgets-dialog
      :open="dialogs.addWidgets"
      :widgets="widgets"
      :widgetMaxDevices="widgetMaxDevices"
      @close="closeAddWidgets"
      @widgetAdded="addNewWidget($event)"
      data-cy="addWidgetsDialog"
    />
    <remove-warning-dialog
      :open="dialogs.removeWarning"
      @ok="removeWidget"
      @cancel="clearWidgetToRemove"
    />
  </v-card>
</template>

<script>
import {
  accountExists,
  emailVerified,
  isAuthorized,
  routeHasPermission,
} from '@fusion/auth'
import { SlugsEnum } from '../permissions/SlugsEnum'
import { activeLastDay } from '../services/device-status'

import VueGridLayout from 'vue-grid-layout'
import BaseWidget from '../components/widgets/BaseWidget.vue'
import LoadingContainer from '../components/devicePopover/content/layouts/LoadingContainer.vue'
import PopoverWidget from '../components/widgets/currentReadings/CurrentReadings.vue'
import MapWidget from '../components/widgets/MapWidget.vue'
import HistoryWidget from '../components/widgets/HistoryWidget.vue'
import AddWidgetsDialog from '../components/dialogs/AddWidgetsDialog'
import RemoveWarningDialog from '../components/dialogs/RemoveWarningDialog.vue'
import throttle from 'lodash/throttle'
import {
  messageTypes,
  SNACKBAR_STATIC_DURATION_MS,
} from '../services/notifications'
import { AddUserSetting } from '../services/user-settings'
import { HTTPStatus } from '../api'
import { checkStatus } from '../api/services/utils'
import { featureFlags } from '../services/feature-flags'
import { getUserAccountId } from '../helpers/loginas/logInAsHelper'

const MAX_DEVICES = 1
const MAX_DEVICES_HISTORY = 5

export default {
  mixins: [
    isAuthorized,
    accountExists,
    emailVerified,
    routeHasPermission(SlugsEnum.SideBarDashboardExecute),
  ],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    AddWidgetsDialog,
    RemoveWarningDialog,
    BaseWidget,
    LoadingContainer,
    PopoverWidget,
    MapWidget,
    HistoryWidget,
  },
  data() {
    return {
      accountId: null,
      totalDevices: 0,
      activeDevices: 0,
      inactiveDevices: 0,
      deviceIds: null,
      grid: {
        dims: {
          w: -1,
          h: -1,
        },
        layout: [],
        originalLayout: [],
        layouts: {},
        layoutsLocked: {},
        widgetMoved: false,
        loading: true,
        rowHeight: 450,
        margins: {
          lg: [40, 40],
          md: [40, 40],
          sm: [24, 24],
          xs: [24, 24],
          xxs: [16, 16],
        },
        isDraggable: true,
        isResizable: true,
        breakpoints: { lg: 2000, md: 1600, sm: 1200, xs: 700, xxs: 0 },
        cols: { lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 },
        sizes: {
          sm: { w: 1, h: 1 },
          md: { w: 2, h: 1 },
          lg: { w: 2, h: 2 },
        },
      },
      widgets: [],
      widgetSizes: {
        MapWidget: ['sm', 'md', 'lg'],
        PopoverWidget: ['sm'],
        HistoryWidget: ['sm', 'md', 'lg'],
      },
      widgetMaxDevices: {
        MapWidget: MAX_DEVICES,
        PopoverWidget: MAX_DEVICES,
        HistoryWidget: MAX_DEVICES_HISTORY,
      },
      widgetToRemove: null,
      dialogs: {
        addWidgets: false,
        removeWarning: false,
      },
    }
  },
  computed: {
    /**
     * Compare grid element width to specified breakpoints
     * @returns String of current viewport
     */
    viewport() {
      let viewport
      if (this.grid.dims.w >= this.grid.breakpoints.xxs) viewport = 'xxs'
      if (this.grid.dims.w >= this.grid.breakpoints.xs) viewport = 'xs'
      if (this.grid.dims.w >= this.grid.breakpoints.sm) viewport = 'sm'
      if (this.grid.dims.w >= this.grid.breakpoints.md) viewport = 'md'
      if (this.grid.dims.w >= this.grid.breakpoints.lg) viewport = 'lg'
      return viewport
    },
    /**
     * @returns Number of grid columns for current viewport
     */
    colNum() {
      return this.viewport ? this.grid.cols[this.viewport] : 3
    },
    /**
     * @returns Row height proportional to grid column width (not currently used)
     */
    rowHeight() {
      return Math.round(this.grid.dims.w / this.colNum)
    },
    margin() {
      return this.viewport ? this.grid.margins[this.viewport] : [24, 24]
    },
    cssVars() {
      return {
        '--row-height': `${this.grid.rowHeight}px`,
        '--widget-margin': `${this.margin[0]}px`,
      }
    },
    /**
     * Build a 2D grid map of the layout for each viewport
     * Marks each cell with widget ID or as empty
     * @return  {Object} with properties for each viewport
     *          Each viewport {Object} includes width {Number},
     *          height {Number}, and map {Array} properties
     */
    gridMaps() {
      const grids = {}
      for (const viewport in this.grid.layouts) {
        const layout = this.grid.layouts[viewport]
        const width = this.grid.cols[viewport]
        const height =
          layout.length > 0
            ? Math.max(...layout.map((item) => item.y + item.h))
            : 1
        const map = this.buildGridMap(layout, width, height)

        grids[viewport] = {
          width,
          height,
          map,
        }
      }
      return grids
    },
    /**
     * Used for ease of lookup during grid render
     * @returns Object of widgets keyed by widgetId
     */
    widgetsById() {
      const ret = this.arrayToObject(this.widgets, 'id')
      return ret
    },
    showEmptyMessage() {
      return this.grid?.layout?.length === 0 && this.widgets?.length === 0
    },
    hasNotifications() {
      return this.$store.hasModule('notifications')
    },
    verticalCompact() {
      const isEnabled = this.$store.getters[
        'featureFlags/getFeatureFlagBySlug'
      ](featureFlags.DashboardVerticalCompactEnabled)
      // Dashboard launched with vertical-compact set to true
      // This preserves that legacy behavior
      // if the feature flag's value is not explicitly false
      return isEnabled === false ? false : true
    },
    restoreOnDrag() {
      const isEnabled = this.$store.getters[
        'featureFlags/getFeatureFlagBySlug'
      ](featureFlags.DashboardRestoreOnDragEnabled)
      return isEnabled ? true : false
    },
    devicesLength() {
      return Object.entries(this.$store.state.devices.allDevices).length
    },
  },
  watch: {
    /**
     * Changes layout when viewport changes
     */
    viewport(newBreakpoint, oldBreakpoint) {
      if (oldBreakpoint && this.grid.widgetMoved) {
        this.resetWidgetOrder(oldBreakpoint)
        this.grid.widgetMoved = false
      }
      this.grid.layout = this.grid.layouts[newBreakpoint]
        ? this.grid.layouts[newBreakpoint]
        : []
      // Create a layout for widgets without one.
      this.patchLayouts()
    },
  },
  methods: {
    updateDeviceCounts() {
      let totalDevices = 0
      let activeDevices = 0
      let inactiveDevices = 0
      let deviceIds = []

      for (const key in this.$store.state.devices.name) {
        const isShared = this.$store.state.devices.shared[key]
        const isOwned =
          this.accountId === this.$store.state.devices.accountId[key]
        if (isShared || isOwned) {
          totalDevices++
          deviceIds.push(key)
          const lastContact =
            this.$store.getters['devices/getLastContactByDeviceId'](key)
          if (activeLastDay(lastContact)) {
            activeDevices++
          } else {
            inactiveDevices++
          }
        }
      }
      this.totalDevices = totalDevices
      this.activeDevices = activeDevices
      this.inactiveDevices = inactiveDevices
      this.deviceIds = deviceIds
    },
    async getWidgets() {
      const resp = await this.$api.getWidgets()
      if (resp.ok) {
        const widgets = await resp.json()
        widgets.forEach((widget) => this.setWidgetSizes(widget))
        this.widgets = widgets
      } else {
        if (this.hasNotifications) {
          const message = {
            text: this.$t('dashboard.messages.warningLoading'),
            type: messageTypes.WARNING,
            timeout: SNACKBAR_STATIC_DURATION_MS,
          }
          this.$store.dispatch('notifications/addMessage', message)
        }
      }
    },
    async getLayouts() {
      let layouts = {}
      let resp = await this.$api.getLayout()
      if (resp.ok) {
        const body = await resp.json()
        layouts = body.layouts
      } else {
        // The dashboard layout collection and document do not exist
        // until a user visits the Dashboard for the first time.
        // The API response will include a status code 404,
        // and we don’t want to trigger a snackbar warning in this case.
        if (resp.status !== HTTPStatus.NotFound) {
          if (this.hasNotifications) {
            const message = {
              text: this.$t('dashboard.messages.warningLoading'),
              type: messageTypes.WARNING,
              timeout: SNACKBAR_STATIC_DURATION_MS,
            }
            this.$store.dispatch('notifications/addMessage', message)
          }
        }
      }

      // create empty layouts for all breakpoints if none are returned
      const isEmpty =
        Object.values(layouts).every((x) => x === null || x === '') ||
        Object.keys(layouts).length === 0
      if (isEmpty) {
        for (let breakpoint in this.grid.breakpoints) {
          layouts[breakpoint] = []
        }
        this.grid.layouts = layouts

        // add any pre-existing widgets to the layouts
        this.addWidgetsToLayout(this.widgets)
      } else {
        this.grid.layouts = layouts
        this.updateLayoutItemSizes()
      }
    },
    patchLayouts() {
      this.widgets.forEach((widget) => {
        const found = this.grid.layout.find((item) => item.i === widget.id)
        if (!found) this.addWidgetsToLayout([widget])
      })
    },
    async saveLayouts() {
      if (!this.grid.loading) {
        if (this.accountId) {
          const layouts = {
            accountId: this.accountId,
            layouts: this.grid.layouts,
          }
          await this.$api.postLayout(layouts)
        }
      }
    },
    /**
     * Set layouts to locked if they contain a userModified item
     */
    setLockedLayouts() {
      const locked = {}
      for (let viewport in this.grid.layouts) {
        const moved = this.grid.layouts[viewport].some(
          (item) => item.userModified === true
        )
        locked[viewport] = moved
      }
      this.grid.layoutsLocked = locked
    },
    layoutUpdated() {
      this.saveLayouts()
      this.setLockedLayouts()
    },
    openAddWidgets() {
      this.dialogs.addWidgets = true
    },
    closeAddWidgets() {
      this.dialogs.addWidgets = false
    },
    async addNewWidget(event) {
      if (event && event.deviceIds && event.widgetComponent) {
        const newWidget = {
          deviceIds: event.deviceIds,
          widgetComponent: event.widgetComponent,
        }

        const resp = await this.$api.postWidget(newWidget)
        if (resp.ok) {
          this.refreshDashboard()
        } else {
          if (this.hasNotifications) {
            const message = {
              text: this.$t('dashboard.messages.errorAddWidget'),
              type: messageTypes.ERROR,
              timeout: SNACKBAR_STATIC_DURATION_MS,
            }
            this.$store.dispatch('notifications/addMessage', message)
          }
        }
      }
    },
    async removeWidget() {
      const index = this.widgets.findIndex(
        (item) => item.id === this.widgetToRemove
      )
      if (typeof index === 'number') {
        const widget = this.widgets[index]

        const resp = await this.$api.deleteWidget(widget.id)
        if (resp.ok || resp.status === HTTPStatus.NotFound) {
          this.removeWidgetsFromLayout([widget])
          this.widgets.splice(index, 1)
        } else {
          if (this.hasNotifications) {
            const message = {
              text: this.$t('dashboard.messages.errorRemoveWidget'),
              type: messageTypes.ERROR,
              timeout: SNACKBAR_STATIC_DURATION_MS,
            }
            this.$store.dispatch('notifications/addMessage', message)
          }
        }

        this.clearWidgetToRemove()
      }
    },
    async refreshDashboard() {
      await this.getWidgets()
      this.patchLayouts()
    },
    /**
     * Add passed widgets to all layouts
     * @param {Array} widgets to add
     * @param {Boolean} skipLockedLayouts (optional) flag for whether locked layouts should be skipped
     */
    addWidgetsToLayout(widgets, skipLockedLayouts = false) {
      for (let viewport in this.grid.layouts) {
        if (skipLockedLayouts && this.grid.layoutsLocked[viewport]) {
          continue
        }
        widgets.forEach((widget) => {
          this.addItem(widget, viewport)
        })
      }
    },
    /**
     * Remove passed widgets to all layouts
     * @param {Array} widgets to remove
     * @param {Boolean} skipLockedLayouts (optional) flag for whether locked layouts should be skipped
     */
    removeWidgetsFromLayout(widgets, skipLockedLayouts = false) {
      for (let viewport in this.grid.layouts) {
        if (skipLockedLayouts && this.grid.layoutsLocked[viewport]) {
          continue
        }
        widgets.forEach((widget) => {
          this.removeItem(widget, viewport)
        })
      }
    },
    /**
     * Updates layout items dims to match contained widget
     * Can be called for a single widget
     * @param {Object} widget for which to update items or null to update all items
     */
    updateLayoutItemSizes(widget = null) {
      for (let viewport in this.grid.layouts) {
        const layout = this.grid.layouts[viewport]
        if (widget) {
          const layoutItem = layout.find((item) => item.i === widget.id)
          if (layoutItem) {
            layoutItem.isResizable = widget.isResizable
            layoutItem.w = widget.w
            layoutItem.h = widget.h
            layoutItem.maxW = widget.maxW
            layoutItem.maxH = widget.maxH
          }
        } else {
          layout.forEach((item) => {
            const itemWidget = this.widgetsById[item.i]
            if (itemWidget) {
              item.isResizable = itemWidget.isResizable
              item.w = itemWidget.w
              item.h = itemWidget.h
              item.maxW = itemWidget.maxW
              item.maxH = itemWidget.maxH
            }
          })
        }
      }
    },
    /**
     * Determine size support for widget
     * set w/h and resize min/max accordingly
     * @param {Object} item widget to update
     */
    setWidgetSizes(item) {
      const sizes = this.widgetSizes[item.widgetComponent]
      const minW = Math.min(...sizes.map((size) => this.grid.sizes[size].w))
      const minH = Math.min(...sizes.map((size) => this.grid.sizes[size].h))
      const maxW = Math.max(...sizes.map((size) => this.grid.sizes[size].w))
      const maxH = Math.max(...sizes.map((size) => this.grid.sizes[size].h))

      item.isResizable = minW === maxW && minH === maxH ? false : true
      item.w =
        item.properties.w >= minW && item.properties.w <= maxW
          ? item.properties.w
          : minW
      item.h =
        item.properties.h >= minH && item.properties.w <= maxH
          ? item.properties.h
          : minH
      item.maxW = maxW
      item.maxH = maxH

      return item
    },
    /**
     * Adds a new item to all the grid layouts
     * Item is placed at the end of the grid
     * @param {Object} item widget to add
     * @param {String} viewport to update
     */
    addItem(item, viewport) {
      // set size support for widget
      item = this.setWidgetSizes(item)

      // get the next open cell
      const { x, y } = this.getNextOpenCell(viewport, item)

      // get the layout for the given layout and add item
      const layout = this.grid.layouts[viewport]

      layout.push({
        x: x,
        y: y,
        w: item.w,
        h: item.h,
        maxW: item.maxW,
        maxH: item.maxH,
        isResizable: item.isResizable,
        i: item.id,
      })
    },
    /**
     * Removes an item from all grid layouts
     * @param {Object} item widget to remove
     * @param {String} viewport to update
     */
    removeItem(item, viewport) {
      const layout = this.grid.layouts[viewport]
      const index = layout.map((el) => el.i).indexOf(item.id)
      layout.splice(index, 1)
    },
    /**
     * Find a widget in the widgets array
     * @param {String} id of widget
     * @returns Object of widget
     */
    getWidget(id) {
      return this.widgets.find((widget) => {
        return widget.id === id
      })
    },
    handleRemoveWidget(removeId, options) {
      this.widgetToRemove = removeId
      if (options?.force) {
        this.removeWidget()
      } else {
        this.dialogs.removeWarning = true
      }
    },
    clearWidgetToRemove() {
      this.widgetToRemove = null
      this.dialogs.removeWarning = false
    },
    /**
     * Set the flag that a widget moved
     * Add the userModified property to the layout item (used to lock layout)
     */
    setWidgetMoved(i) {
      this.grid.widgetMoved = true
      this.grid.layouts[this.viewport].find(
        (item) => item.i === i
      ).userModified = true
    },
    /**
     * Get the layout order of widgets for the given grid map
     * @param {Array} map to evaluate
     * @return {Array} of item IDs in display order
     */
    getLayoutOrder(map) {
      const itemSet = new Set()
      for (const row of map) {
        for (const cell of row) {
          if (cell !== '') {
            itemSet.add(cell)
          }
        }
      }
      return [...itemSet]
    },
    /**
     * Reset the widget order on non-visible layouts
     * to match the order of the visible layout
     * Locked layouts will not be updated
     * @param {String} viewport to use as sort order
     */
    resetWidgetOrder(viewport) {
      // get the rendered order of items in the visible layout
      const map = this.gridMaps[viewport].map
      const order = this.getLayoutOrder(map)

      // sort widgets by layout order
      const sorted = [...this.widgets].sort((a, b) => {
        return order.indexOf(a.id) > order.indexOf(b.id) ? 1 : -1
      })

      // remove widgets from layouts, skipping locked layouts
      this.removeWidgetsFromLayout(this.widgets, true)
      // add sorted widgets back to layouts, skipping locked layouts
      this.addWidgetsToLayout(sorted, true)
    },
    /**
     * Set the grid and pixel dimensions on the widget
     * when the grid item changes size
     */
    setWidgetDims(i, newH, newW, newHPx, newWPx) {
      const widget = this.getWidget(i)
      let updateItem = false
      if (newW !== widget.w) {
        widget.w = parseInt(newW)
        updateItem = true
      }
      if (newH !== widget.h) {
        widget.h = parseInt(newH)
        updateItem = true
      }
      if (updateItem) {
        this.updateLayoutItemSizes(widget)
        this.$api.patchWidget({ properties: { w: newW, h: newH } }, widget.id)
      }
      widget.width = parseInt(newWPx)
      widget.height = parseInt(newHPx)
    },
    toggleFullscreen(widgetId, isFullscreen) {
      for (let viewport in this.grid.layouts) {
        const layout = this.grid.layouts[viewport]
        const item = layout.find((el) => el.i === widgetId)
        item.isFullscreen = isFullscreen
      }
      // TODO: render doesn't update without forcing it. Look into why and try to fix
      this.$forceUpdate()
    },
    updateWidgetDevices(widgetId, deviceIds) {
      const widget = this.widgetsById[widgetId]
      widget.deviceIds = deviceIds
    },
    /**
     * Collect all open cells for the given grid map
     * @param   {Array} map to loop over
     * @return  {Array} of open cells
     */
    getOpenCells(map) {
      const cells = []

      for (let y = 0; y < map.length; y++) {
        for (let x = 0; x < map[y].length; x++) {
          if (map[y][x] === '') {
            cells.push({ x, y })
          }
        }
      }
      return cells
    },
    /**
     * Find the next open cell in the grid for a given viewport
     * large enough to place the a new widget
     * @return  {Object} with cell coordinates
     */
    getNextOpenCell(viewport, item) {
      const grid = this.gridMaps[viewport]
      const { map, height } = grid
      const openCells = this.getOpenCells(map)

      // find first open cell with enough open adjacent cells
      // to fit the size of the widget being placed
      // return null if suitable space is not found
      const nextCell = openCells.find((cell) => {
        const isOpen = []
        for (let y = cell.y; y < cell.y + item.h; y++) {
          for (let x = cell.x; x < cell.x + item.w; x++) {
            let open = false
            // if map[y] is undefined, it is a new row to be added
            if (map[y] === undefined || map[y][x] === '') {
              open = true
            }
            isOpen.push(open)
          }
        }
        return isOpen.includes(false) ? null : cell
      })

      // define new cell coordinates
      const newCell = { x: 0, y: height }

      return nextCell || newCell
    },
    /**
     * Build 2D grid map for given layout
     * @param  {Array} layout array of items to map
     * @param  {Number} width of grid
     * @param  {Number} height of grid
     * @return {Array} 2D array of cells with widget locations marked
     */
    buildGridMap(layout, width, height) {
      // build empty grid map
      const map = []
      for (let y = 0; y < height; y++) {
        map[y] = []
        for (let x = 0; x < width; x++) {
          map[y][x] = ''
        }
      }

      // mark occupied cells in grid map
      layout.forEach((item) => {
        for (let y = item.y; y < item.y + item.h; y++) {
          for (let x = item.x; x < item.x + item.w; x++) {
            // items that are normally wider than one cell
            // are forced to one cell width at XXS viewport
            // only update array elements that exist in grid map
            if (map[y][x] === undefined) {
              continue
            }
            map[y][x] = item.i
          }
        }
      })

      return map
    },
    arrayToObject(array, key) {
      let object = {}
      array.forEach((item) => {
        object[item[key]] = item
      })
      return object
    },
    objectToArray(object) {
      return Object.values(object)
    },
    /**
     * Sets up resize observer on the grid element
     * Observed dimensions used to determine viewport size
     */
    observe(el) {
      const setGridDims = throttle((entries) => {
        const cr = entries[0].contentRect
        this.grid.dims.w = cr.width
        this.grid.dims.h = cr.height
      }, 200)

      this.observer = new ResizeObserver(setGridDims)
      if (el instanceof Element) {
        this.observer.observe(el)
      }
    },
    getWidgetsIds() {
      if (this.widgets && this.widgets.length) {
        return this.widgets.map((widget) => {
          return widget.id
        })
      }

      return []
    },
    filterLayoutsWithoutWidgets() {
      let layouts = null
      if (this.grid && this.grid.layouts) {
        layouts = {...this.grid.layouts}
        const widgetIds = this.getWidgetsIds()
        for (const layoutBreakpoint in layouts) {
          layouts[layoutBreakpoint] = layouts[layoutBreakpoint].filter((layout) => {
            return widgetIds.indexOf(layout.i) !== -1
          })
        }
      }

      return layouts
    },
    async sanitizeLayouts() {
      const filteredLayoutsWithoutWidgets = this.filterLayoutsWithoutWidgets()
      if (filteredLayoutsWithoutWidgets && this.grid && this.grid.layouts) {
        if (JSON.stringify(filteredLayoutsWithoutWidgets) !== JSON.stringify(this.grid.layouts)) {
          this.grid.layouts = filteredLayoutsWithoutWidgets
          await this.saveLayouts()
        }
      }
    },
    stopLoading() {
      this.grid.loading = false
    },
  },
  async mounted() {
    this.observe(this.$refs.dashboardGrid.$el)
    this.accountId = await getUserAccountId(this.$auth)
    this.updateDeviceCounts()
    await this.getWidgets()
    await this.getLayouts()
    await this.sanitizeLayouts()
    this.stopLoading()
    this.$store.watch(
      (state) => state.devices.accountId,
      () => {
        this.updateDeviceCounts()
      },
      { immediate: true }
    )
  },
  destroyed() {
    if (this.observer) this.observer.disconnect()
  },
}
</script>

<style lang="scss">
.vue-grid-layout {
  background: transparent;
  // margin: -8px;
}

.vue-grid-item {
  touch-action: pan-x pan-y !important;
  width: 400px;

  &.vue-grid-placeholder {
    background: #00aeef !important;
    border-radius: 4px;

    .vue-resizable-handle {
      display: none;
    }
  }

  &:not(.vue-grid-placeholder) {
    background: transparent;
  }

  &.widget--fullscreen {
    position: fixed !important;
    z-index: 5;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    overflow-y: auto;
    transform: none !important;
  }

  .debug {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 75%;
    line-height: 1;
    margin: 0.25em 0;
  }

  .widget {
    width: 100%;
    height: 100%;
  }

  .resizing {
    opacity: 0.9;
  }

  .vue-resizable-handle {
    touch-action: none;
    border-radius: 4px;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M22,22H20V20H22V22M22,18H20V16H22V18M18,22H16V20H18V22M18,18H16V16H18V18M14,22H12V20H14V22M22,14H20V12H22V14Z' /%3E%3C/svg%3E");
  }
}

.vue-draggable-handle {
  touch-action: none;
  cursor: grab;

  .vue-draggable-dragging & {
    cursor: grabbing;
  }

  .widget-fullscreen & {
    cursor: default;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/global-variables.scss';
.dashboard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  border-radius: 0;
  box-shadow: none;
  background: $background-gradient;
  padding-top: calc(var(--widget-margin) / 4);
  padding-right: var(--widget-margin);
  padding-bottom: calc(var(--row-height) + var(--widget-margin));
  padding-left: var(--widget-margin);

  .headline {
    display: inline-block;
    padding-left: 0;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
  }
}

.dashboard-grid {
  margin-top: -8px;
  margin-right: calc(var(--widget-margin) * -1);
  margin-bottom: 0;
  margin-left: calc(var(--widget-margin) * -1);
}

.headline {
  font-weight: 300;
  padding-bottom: 0;
}

.v-btn.icon-btn {
  min-width: 48px;
  padding: 0;
}
.card-title-btn {
  vertical-align: bottom;
  margin-left: 8px;
}
.append-plus {
  margin-right: 0.25em;

  &::after {
    content: '+';
    width: 0.5em;
    height: auto;
    left: 100%;
    top: 10%;
    z-index: 1;
    background-color: transparent;
    opacity: 1;
    font-size: 0.5em;
    font-style: normal;
    font-weight: 700;
  }
}

.devices-summary {
  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
  &__group {
    padding: 0.25em 1em 0;
    &:last-of-type {
      padding-right: 0;
    }
    > span {
      display: inline-block;
      vertical-align: baseline;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .devices-count {
    font-weight: bold;
    font-size: 1.5em;
    padding-right: 0.25em;
  }
  .active {
    color: #00e400;
  }
  .inactive {
    color: #9e9e9e;
  }
  .border-right {
    border-right: 1px solid #cccccc;
  }
}

.empty-message {
  font-size: 16px;
  height: 80px;
  line-height: 240px;
  opacity: 0.56;
}
.disabled-message {
  font-size: 1rem;
  padding: 1rem 0;
  opacity: 0.76;
}
</style>
