<template>
  <div>
    <v-list>
      <v-list-item>
        <v-radio-group
          @change="parameterChange()"
          v-model="selectedParameter"
          :mandatory="false"
          class="radio-buttons"
        >
          <div v-for="(item, index) in parameterOptions" :key="index">
            <v-tooltip bottom :disabled="!item.disabled">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-radio
                    :disabled="item.disabled"
                    :value="item.value"
                    :label="item.label"
                    :key="index"
                    color="primary"
                    :data-cy="'historyDialogParameter' + index"
                  ></v-radio>
                </div>
              </template>
              <span>{{ $t('accountSubscriptions.upgradeData') }}</span>
            </v-tooltip>
          </div>
        </v-radio-group>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Export Data -->
      <v-list-item @click.stop="openExportData" data-cy="exportData">
        <v-icon>mdi-file-download</v-icon>
        <v-list-item-title>{{
          $t('map.historyDialog.dropdown.exportCsv')
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
    <export-data-dialog
      :open="exportData"
      :devices="selectedDevices"
      :permissions="permissions"
      @close="closeExportData"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import ExportDataDialog from '../dialogs/ExportDataDialog.vue'
import {
  measurementPermissions,
  measurementTypes,
  measurementTypesLabelKey,
  measurementTypesOrder,
  measurementTypesValueRange,
} from '../../services/device-measurements'

Vue.component('export-data-dialog', ExportDataDialog)

export default {
  name: 'history-menu',
  props: {
    selectedDevices: Array,
    passedinParameter: Number,
    permissions: Object,
  },
  data() {
    return {
      exportData: false,
      selectedParameter: this.passedinParameter,
    }
  },
  watch: {
    passedinParameter() {
      this.selectedParameter = this.passedinParameter
    },
    parameterOptions() {
      // Reset selection to first option (if available) if selection
      // is not in available measurements set for the selected devices
      if (!this.availableMeasurements.includes(this.selectedParameter)) {
        this.selectedParameter =
          this.parameterOptions.length > 0
            ? this.parameterOptions[0].value
            : this.measurementType
        this.parameterChange()
      }
    },
  },
  computed: {
    measurementType: function () {
      return this.$store.getters['map/getMeasurementType']
    },
    availableMeasurements: function () {
      // Build an array of unique measurements across all selected devices
      const measurements = [
        ...new Set(
          this.selectedDevices
            .map((device) => {
              const isPublicOnly = !device.isMine && !device.isShared
              return this.$store.getters[
                'devicemodels/getSupportedMeasurements'
              ](device.model, device.submodel, isPublicOnly)
            })
            .flat(1)
        ),
      ]

      // Filter measurements based on whether PM 2.5 or PM 10 is selected
      const filtered = measurements.filter((measurement) => {
        if (this.measurementType === measurementTypes.pm25) {
          return (
            measurement !== measurementTypes.pm10 &&
            measurement !== measurementTypes.pm10_raw
          )
        } else {
          return (
            measurement !== measurementTypes.pm25 &&
            measurement !== measurementTypes.pm25_raw
          )
        }
      })

      // Sort the array by the defined sort array
      const sorted = filtered.sort(function (a, b) {
        return (
          measurementTypesOrder.indexOf(a) - measurementTypesOrder.indexOf(b)
        )
      })

      return sorted
    },
    parameterOptions: function () {
      const options = this.availableMeasurements.map((measurement) => {
        const result = {
          value: measurement,
          label: this.$t(
            `map.historyDialog.dropdown.${measurementTypesLabelKey[measurement]}`
          ),
          valueRange: measurementTypesValueRange[measurement],
        }
        result.disabled = !this.isMeasurementAllowed(measurement)
        return result
      })
      return options
    },
    permissionSet: function () {
      const perms = [
        ...new Set(
          this.selectedDevices
            .map((device) => this.permissions[device.id])
            .flat(1)
        ),
      ]
      return perms
    },
  },
  methods: {
    isMeasurementAllowed(permissionType) {
      const slug = measurementPermissions[permissionType]
      if (slug) {
        return this.permissionSet.includes(slug)
      }
      return true
    },
    openExportData: function () {
      this.exportData = true
    },
    closeExportData: function () {
      this.exportData = false
    },
    parameterChange() {
      this.$emit('parameterChange', this.selectedParameter)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-list {
  padding: 0px;
}
</style>
