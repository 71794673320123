<template>
  <v-list>
    <v-list-item>
      <v-radio-group
        @change="parameterChange()"
        v-model="selectedParameter"
        :mandatory="false"
        class="radio-buttons"
      >
        <div v-for="(item, index) in parameterOptions" :key="index">
          <v-tooltip bottom :disabled="!item.disabled">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-radio
                  :disabled="item.disabled"
                  :value="item.value"
                  :label="item.label"
                  :key="index"
                  color="primary"
                  :data-cy="'measurementParameter' + index"
                ></v-radio>
              </div>
            </template>
            <span>{{ $t('accountSubscriptions.upgradeData') }}</span>
          </v-tooltip>
        </div>
      </v-radio-group>
    </v-list-item>
  </v-list>
</template>

<script>
import { UnitOfMeasure } from '@fusion/units'
import {
  measurementPermissions,
  measurementTypes,
  measurementTypesLabelKey,
  measurementTypesUnitsKey,
  measurementTypesOrder,
  measurementTypesValueRange,
} from '../../../services/device-measurements'

export default {
  name: 'history-menu',
  props: {
    selectedDevices: Array,
    passedinParameter: Number,
    permissions: Object,
    filterMeasurements: Boolean,
  },
  data() {
    return {
      selectedParameter: this.passedinParameter,
    }
  },
  mounted() {
    this.checkSelectedParameter()
  },
  watch: {
    passedinParameter() {
      this.selectedParameter = this.passedinParameter
    },
    selectedDevices() {
      this.checkSelectedParameter()
    },
  },
  computed: {
    measurementType() {
      return this.$store.getters['map/getMeasurementType']
    },
    availableMeasurements() {
      // Build an array of unique measurements across all selected devices
      let measurements = [
        ...new Set(
          this.selectedDevices
            .map((device) => {
              const isPublicOnly = !device.isMine && !device.isShared
              return this.$store.getters[
                'devicemodels/getSupportedMeasurements'
              ](device.model, device.submodel, isPublicOnly)
            })
            .flat(1)
        ),
      ]

      // Filter measurements based on whether PM 2.5 or PM 10 is selected
      if (this.filterMeasurements) {
        measurements = measurements.filter((measurement) => {
          if (this.measurementType === measurementTypes.pm25) {
            return (
              measurement !== measurementTypes.pm10 &&
              measurement !== measurementTypes.pm10_raw
            )
          } else {
            return (
              measurement !== measurementTypes.pm25 &&
              measurement !== measurementTypes.pm25_raw
            )
          }
        })
      }

      // Sort the array by the defined sort array
      return measurements.sort(function (a, b) {
        return (
          measurementTypesOrder.indexOf(a) - measurementTypesOrder.indexOf(b)
        )
      })
    },
    parameterOptions() {
      const options = this.availableMeasurements.map((measurement) => {
        const result = {
          value: measurement,
          label: this.$t(
            `map.historyDialog.dropdown.${measurementTypesLabelKey[measurement]}`
          ),
          valueRange: measurementTypesValueRange[measurement],
          units: measurementTypesUnitsKey[measurement]
            ? this.$t(measurementTypesUnitsKey[measurement])
            : '',
        }
        if (measurement === measurementTypes.temp) {
          result.units =
            this.$store.state.userProfile.settings.tempUnit ===
            UnitOfMeasure.DEGREES_F
              ? this.$t('units.fahrenheit')
              : this.$t('units.celsius')
        }
        result.disabled = !this.isMeasurementAllowed(measurement)
        return result
      })
      return options
    },
    permissionSet() {
      const perms = [
        ...new Set(
          this.selectedDevices
            .map((device) => this.permissions[device.id])
            .flat(1)
        ),
      ]
      return perms
    },
  },
  methods: {
    isMeasurementAllowed(permissionType) {
      const slug = measurementPermissions[permissionType]
      if (slug) {
        return this.permissionSet.includes(slug)
      }
      return true
    },
    parameterChange() {
      this.$emit('parameterChange', this.selectedParameter)
    },
    checkSelectedParameter() {
      // Reset selection to first option (if available) if selection
      // is not in available measurements set for the selected devices
      if (
        this.availableMeasurements?.length &&
        !this.availableMeasurements.includes(this.selectedParameter)
      ) {
        this.selectedParameter =
          this.parameterOptions.length > 0
            ? this.parameterOptions[0].value
            : this.measurementType
        this.parameterChange()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.v-list {
  padding: 0px;
}
</style>
