var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"popover-measurement",class:{
        notSupported: !_vm.isSupported,
        measurement: !_vm.largeMeasurement,
        'measurement-large': _vm.largeMeasurement,
        'empty-reading': !_vm.readingIsValid || !_vm.readingActive,
      }},'div',attrs,false),on),[_c('div',{staticClass:"label"},[_c('img',{staticClass:"measurement-icon",attrs:{"src":_vm.imageSrc,"alt":"Measurement Icon"}}),_c('span',[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"reading"},[_vm._v(" "+_vm._s(_vm.value)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.value !== _vm.$t('map.popover.valueBlank')),expression:"value !== $t('map.popover.valueBlank')"}]},[_c('span',{staticClass:"uom"},[_vm._v(_vm._s(_vm.unitsLabel))])])])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])}
var staticRenderFns = []

export { render, staticRenderFns }