<template>
  <v-card-subtitle class="small-subtitle" data-cy="lastCalibrationDate">
    <span> {{ $t('map.popover.lastCalibrationDate') }}: </span>
    <span class="value">
      {{ lastCalibrationDate }}
    </span>
  </v-card-subtitle>
</template>

<script>
export default {
  props: {
    deviceId: String,
  },
  computed: {
    lastCalibrationDate() {
      return (
        this.$store.getters['devices/getLastCalibrationDate'](this.deviceId) ||
        this.$t('map.popover.timestamp.lastMeasurementNever')
      )
    },
  },
}
</script>
