import { SlugsEnum } from '../permissions/SlugsEnum'

export const measurementTypes = Object.freeze({
  pm25: 0,
  pm10: 1,
  temp: 2,
  rh: 3,
  pm25_raw: 4,
  pm10_raw: 5,
  baro_inhg: 6,
  co: 7,
  co2: 8,
  o3: 9,
  tvoc: 10,
  so2: 11,
  no2: 12,
  ch2o: 13,
  pm1x0: 14,
  pm4x0: 15,
  pm_total: 16,
})

export const measurementAlertPermissions = Object.freeze({
  pm25: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm10: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  temp: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  rh: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm25_raw: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm10_raw: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  co: SlugsEnum.AlertSetupCreateAlertCO,
  o3: SlugsEnum.AlertSetupCreateAlertO3,
  tvoc: SlugsEnum.AlertSetupCreateAlertTVOC,
  so2: SlugsEnum.AlertSetupCreateAlertSO2,
  no2: SlugsEnum.AlertSetupCreateAlertNO2,
  ch2o: SlugsEnum.AlertSetupCreateAlertCH2O,
})

export const measurementPermissions = Object.freeze({
  0: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  1: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  2: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  3: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  4: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  7: SlugsEnum.GasReadCO,
  9: SlugsEnum.GasReadO3,
  10: SlugsEnum.GasReadTVOC,
  11: SlugsEnum.GasReadSO2,
  12: SlugsEnum.GasReadNO2,
  13: SlugsEnum.GasReadCH2O,
})

export const measurementTypesKey = Object.freeze({
  0: 'mcpm2x5_aqi',
  1: 'mcpm10_aqi',
  2: 'temp_c',
  3: 'rh_percent',
  4: 'mcpm2x5',
  5: 'mcpm10',
  6: 'baro_inhg',
  7: 'co_ppm',
  8: 'co2_ppm',
  9: 'o3_ppb',
  10: 'voc_mgm3',
  11: 'so2_ppb',
  12: 'no2_ppb',
  13: 'ch2o_ppb',
  14: 'mcpm1x0',
  15: 'mcpm4x0',
  16: 'mcpm_total'
})

export const sensorTypesKey = Object.freeze({
  0: 'pm',
  1: 'pm',
  2: 'temp_rh',
  3: 'temp_rh',
  4: 'pm',
  5: 'pm',
  6: 'baro',
  7: 'gas_co',
  8: 'gas_co2',
  9: 'gas_o3',
  10: 'gas_voc',
  11: 'gas_so2',
  12: 'gas_no2',
  13: 'gas_ch2o',
  14: 'pm',
  15: 'pm',
  16: 'pm'
})

export const measurementTypesLabelKey = Object.freeze({
  0: 'aqi25',
  1: 'aqi10',
  2: 'temperature',
  3: 'humidity',
  4: 'pm25',
  5: 'pm10',
  6: 'pressure',
  7: 'co',
  8: 'co2',
  9: 'o3',
  10: 'tvoc',
  11: 'so2',
  12: 'no2',
  13: 'ch2o',
  14: 'pm1x0',
  15: 'pm4x0',
  16: 'pmTotal'
})

export const measurementFriendlyNameKey = Object.freeze({
  0: 'map.historyDialog.dropdown.aqi25',
  1: 'map.historyDialog.dropdown.aqi10',
  2: 'map.historyDialog.dropdown.temperature',
  3: 'map.historyDialog.dropdown.humidity',
  4: 'map.historyDialog.dropdown.pm25',
  5: 'map.historyDialog.dropdown.pm10',
  6: 'map.historyDialog.dropdown.pressure',
  7: 'map.historyDialog.dropdown.co',
  8: 'map.historyDialog.dropdown.co2',
  9: 'map.historyDialog.dropdown.o3',
  10: 'map.historyDialog.dropdown.tvoc',
  11: 'map.historyDialog.dropdown.so2',
  12: 'map.historyDialog.dropdown.no2',
  13: 'map.historyDialog.dropdown.ch2o',
  14: 'map.historyDialog.dropdown.pm1x0',
  15: 'map.historyDialog.dropdown.pm4x0',
  16: 'map.historyDialog.dropdown.pmTotal',
})

export const measurementTypesValueRange = Object.freeze({
  0: [0, null],
  1: [0, null],
  2: [null, null],
  3: [null, null],
  4: [0, null],
  5: [0, null],
  6: [null, null],
  7: [null, null],
  8: [null, null],
  9: [null, null],
  10: [null, null],
  11: [null, null],
  12: [null, null],
  13: [null, null],
  14: [null, null],
  15: [null, null],
  16: [null, null],
})

export const measurementTypesUnitsKey = Object.freeze({
  0: undefined,
  1: undefined,
  2: undefined,
  3: 'units.percent',
  4: undefined,
  5: undefined,
  6: 'units.inchMercury',
  7: 'units.partsPerMillion',
  8: 'units.partsPerMillion',
  9: 'units.partsPerBillion',
  10: 'units.mgm3',
  11: 'units.partsPerBillion',
  12: 'units.partsPerBillion',
  13: 'units.partsPerBillion',
  14: undefined,
  15: undefined,
  16: undefined,
})

export const measurementImgSrc = Object.freeze({
  0: '/img/measurements/pm.svg',
  1: '/img/measurements/pm.svg',
  2: '/img/measurements/temp.svg',
  3: '/img/measurements/rh.svg',
  4: '/img/measurements/pm.svg',
  5: '/img/measurements/pm.svg',
  6: '/img/measurements/bp.svg',
  7: '/img/measurements/co.svg',
  8: '/img/measurements/co2.svg',
  9: '/img/measurements/o3.svg',
  10: '/img/measurements/voc.svg',
  11: '/img/measurements/so2.svg',
  12: '/img/measurements/no2.svg',
  13: '/img/measurements/ch2o.svg',
  14: '/img/measurements/pm.svg',
  15: '/img/measurements/pm.svg',
  16: '/img/measurements/pm.svg',
})

export const measurementTypesOrder = Object.freeze([
  measurementTypes.pm1x0,
  measurementTypes.pm25,
  measurementTypes.pm4x0,
  measurementTypes.pm10,
  measurementTypes.pm25_raw,
  measurementTypes.pm10_raw,
  measurementTypes.pm_total,
  measurementTypes.rh,
  measurementTypes.temp,
  measurementTypes.baro_inhg,
  measurementTypes.co,
  measurementTypes.co2,
  measurementTypes.o3,
  measurementTypes.tvoc,
  measurementTypes.so2,
  measurementTypes.no2,
  measurementTypes.ch2o,
])

export const publicMeasurementPermissions = Object.freeze([
  SlugsEnum.GasReadPM10_PM25_Temp_RH,
])
